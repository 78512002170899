import ChipList from '@component/ChipList';
import Dropdown from '@component/Dropdown';
import { useGetPreAlarmList } from '@queries/common/usePreAlarms';
import { flatMapInfiniteData } from '@utils/query.utils';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { Form, Icon, Label, Table } from 'semantic-ui-react';

type PreAlarmListModuleProps = {
  sectionIdx: number;
  moduleIdx: number;
  module: any;
  handleChange: (e: { target: { name: string; value: any } }) => void;
};

const PreAlarmListModule = ({
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
}: PreAlarmListModuleProps) => {
  const preAlarmIds = (module.data.preAlarmIds || []) as number[];

  // keyword에 상관없이 불러오는 api
  const { data: preAlarmData } = useGetPreAlarmList({});
  const preAlarmList = flatMapInfiniteData(preAlarmData, 'preAlarms', 'id');

  const [keyword, setKeyword] = useState('');
  // 드롭다운에서 keyword에 따라 오픈알림 리스트 불러오는 api
  const {
    data: _allPreAlarmData,
    hasNextPage,
    fetchNextPage,
  } = useGetPreAlarmList({ keyword });
  const allPreAlarmList = flatMapInfiniteData(
    _allPreAlarmData,
    'preAlarms',
    'id',
  );

  const handleClickDelete = (idx: number) => {
    const updatedIds = [...preAlarmIds];
    updatedIds.splice(idx, 1);
    handleInputChange(updatedIds);
  };

  const handleInputChange = (values: number[]) => {
    handleChange({
      target: {
        name: `${sectionIdx}-${moduleIdx}-preAlarmIds`,
        value: values,
      },
    });
  };

  const handleOrderChange = async (idx: number, direction: string) => {
    const _preAlarmIds = [...preAlarmIds];

    const removed = _preAlarmIds.splice(idx, 1);
    if (direction === 'up') {
      if (idx === 0) return;
      _preAlarmIds.splice(idx - 1, 0, removed[0]);
    } else {
      if (idx === preAlarmIds.length - 1) return;
      _preAlarmIds.splice(idx + 1, 0, removed[0]);
    }
    handleInputChange(_preAlarmIds);
  };

  const handleSearchKeyword = useCallback(
    _.debounce((text) => {
      setKeyword(text);
    }, 500),
    [],
  );

  const getPreAlarmName = (preAlarmId: number) => {
    const preAlarm = preAlarmList.find(({ id }) => id === preAlarmId);
    if (!preAlarm) return `[${preAlarmId}]`;
    const { serviceId, title } = preAlarm;
    return `[${serviceId}] ${title}`;
  };

  const handleRemove = (idx: number) => {
    const updatedIds = [...preAlarmIds];
    updatedIds.splice(idx, 1);
    handleInputChange(updatedIds);
  };

  const chipList = preAlarmIds.map((id) => getPreAlarmName(id));

  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 오픈알림 모듈</h4>

      <>
        <Form.Field>
          <ChipList
            style={{ marginBottom: 6 }}
            chipList={chipList}
            removable
            onRemovePress={handleRemove}
          />

          <Dropdown
            search
            label="오픈알림 목록"
            placeholder="챌린지를 선택하세요."
            options={allPreAlarmList.map(({ id }) => ({
              text: getPreAlarmName(id),
              value: id,
            }))}
            keyExtractor={(item) => item}
            onSearchChange={handleSearchKeyword}
            onReachEnd={hasNextPage ? fetchNextPage : undefined}
            onChange={(id) => {
              if (!id || preAlarmIds.includes(id)) return;
              handleInputChange([...preAlarmIds, id]);
            }}
          />
        </Form.Field>

        <h5>노출 순서</h5>
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>제목</Table.HeaderCell>
              <Table.HeaderCell>순서변경</Table.HeaderCell>
              <Table.HeaderCell>삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {chipList.map((name, idx) => (
              <Table.Row key={`${name}-${idx}`}>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                  <Label onClick={() => handleOrderChange(idx, 'up')}>
                    <Icon name="angle up" style={{ margin: 0 }} />
                  </Label>
                  <Label onClick={() => handleOrderChange(idx, 'down')}>
                    <Icon name="angle down" style={{ margin: 0 }} />
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  <Label onClick={() => handleClickDelete(idx)}>
                    <Icon name="close" style={{ margin: 0 }} />
                  </Label>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    </>
  );
};

export default PreAlarmListModule;
