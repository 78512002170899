import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  TextArea,
} from 'semantic-ui-react';
import { ImageRemoveIcon, ImageWrapper } from '../../../../component/Image';
import {
  cropImage,
  handleChangeMultiFiles,
  handleChangeSingleFile,
} from '../../../../utils/uploadImage';
import * as S from './Style';
import EventModule, { CTAButton } from './EventModule';
import {
  modules,
  moduleType,
  newEventTypeList,
} from '../../../../constant/eventType';
import {
  getGoalOptions,
  getChallengeOptions,
  getColorOptions,
  getEventOptions,
  getRaceOptions,
} from '../../../../utils/dropdownOptions';
import { apis } from '../../../../api';
import moment from 'moment';
import { generateId } from '../../../../utils/number';

const EventForm = ({ initEventData, addOrEditEvent }) => {
  const [eventData, setEventData] = useState({ ...initEventData });
  const [moduleModalOpen, setModuleModalOpen] = useState(false);
  const [activeSectionIdx, setActiveSectionIdx] = useState(-1);
  const [challengeOptions, setChallengeOptions] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [raceOptions, setRaceOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [banners, setBanners] = useState([]);
  const colorOptions = getColorOptions();

  useEffect(() => {
    _getDropdownOptions();
  }, []);

  const _getDropdownOptions = async () => {
    const res = await Promise.all([
      getChallengeOptions({
        gteRegisterEndDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        isOfficial: true,
        isPublic: true,
        isDeleted: false,
        limit: 2000,
      }),
      getGoalOptions({ isOfficial: 'True' }),
      getRaceOptions(),
      apis.getBannerForOptionsNotEnded(),
      getEventOptions(),
    ]);

    if (
      !res[0].err &&
      !res[1].err &&
      !res[2].err &&
      !res[3].err &&
      !res[4].err
    ) {
      setChallengeOptions(res[0]);
      setGoalOptions(res[1]);
      setRaceOptions(res[2]);
      setBanners(res[3].banners);
      setEventOptions(res[4]);
    }
  };

  const _handleChange = (e) => {
    const { name, value } = e.target;
    const _eventData = { ...eventData };

    if (['redirectUrl'].includes(name)) {
      _eventData.data[name] = value;
    }

    _eventData[name] = value;
    setEventData(_eventData);
  };

  const _handleSectionChange = (e) => {
    const { name, value } = e.target;
    const _eventData = { ...eventData };
    const splits = name.split('-');
    _eventData.data.sections[splits[0]][splits[1]] = value;
    setEventData(_eventData);
  };

  const _handleOgTextChange = (e) => {
    let { name, value } = e.target;
    value = value.trim();
    const _eventData = { ...eventData };
    _eventData.data.og[name] = value;
    setEventData(_eventData);
  };

  const _handleCTAButtonTextChange = (e) => {
    let { name, value } = e.target;
    value = value.trim();
    const _eventData = { ...eventData };
    if (['raceId'].includes(name)) {
      _eventData.data.ctaButton.payload[name] = value;
    } else {
      _eventData.data.ctaButton[name] = value;
    }
    setEventData(_eventData);
  };

  const _handleCTAButtonRadioChange = (e, { name: key, value }) => {
    const _eventData = { ...eventData };
    if (key === 'raceId') {
      if ('payload' in eventData.data.ctaButton) {
        eventData.data.ctaButton['payload'][key] = value;
      } else {
        eventData.data.ctaButton = {
          payload: { [key]: value },
        };
      }
    } else {
      _eventData.data.ctaButton[key] = value;
    }
    setEventData(_eventData);
  };

  const _handleRadioChange = (e, { name, value }) => {
    const _eventData = { ...eventData };

    if (['startTime', 'endTime'].includes(name)) {
      _eventData[name] = value;
    } else {
      if (name === 'useNavigation') {
        _eventData.data.sections = [
          { id: String(generateId()), name: '', modules: [] },
        ];
      }
      _eventData.data[name] = value;
    }

    setEventData(_eventData);
  };

  const _handleChangeSingleFile = async (e) => {
    const _eventData = { ...eventData };
    const { name } = e.target;
    const temp = await handleChangeSingleFile(e, {});
    _eventData.data.og.image = temp[name];
    setEventData(_eventData);
  };

  const _removeImage = (name, index) => {
    const _eventData = { ...eventData };

    if (name === 'ogImage') {
      _eventData.data.og.image = '';
    } else if (
      ['headerImageUrls', 'eventImageUrls', 'footerImageUrls'].includes(name)
    ) {
      _eventData.data[name].splice(index, 1);
    }

    setEventData(_eventData);
  };

  const _handleModuleChange = (e) => {
    let { name, value } = e.target;
    if (typeof value === 'string') {
      value = value.trim();
    }

    const [sectionIdx, moduleIdx, key, extraKey] = name.split('-');

    if (extraKey) {
      const _eventData = { ...eventData };
      _eventData.data.sections[sectionIdx].modules[moduleIdx]['data'][key][
        extraKey
      ] = value;

      return setEventData(_eventData);
    }

    const _eventData = { ...eventData };
    if (key === 'curationId' && isNaN(value)) {
      return;
    }
    if (key === 'raceId') {
      eventData.data.sections[sectionIdx].modules[moduleIdx]['data']['payload'][
        key
      ] = value;
    } else {
      _eventData.data.sections[sectionIdx].modules[moduleIdx]['data'][key] =
        value;
    }
    setEventData(_eventData);
  };

  const _handleModuleRadioChange = (e, { name, value }) => {
    const _eventData = { ...eventData };
    const [sectionIdx, moduleIdx, key] = name.split('-');
    if (key === 'raceId') {
      if (
        'payload' in
        _eventData.data.sections[sectionIdx].modules[moduleIdx]['data']
      ) {
        _eventData.data.sections[sectionIdx].modules[moduleIdx]['data'][
          'payload'
        ][key] = value;
      } else {
        _eventData.data.sections[sectionIdx].modules[moduleIdx]['data'] = {
          payload: { [key]: value },
        };
      }
    } else {
      _eventData.data.sections[sectionIdx].modules[moduleIdx]['data'][key] =
        value;
    }
    setEventData(_eventData);
  };

  const _handleChangeModuleFile = async (e) => {
    const { name } = e.target;
    const splits = name.split('-');

    if (splits[2] === 'imageUrl') {
      const _eventData = { ...eventData };
      const imageObj = await handleChangeSingleFile(e, {});

      _eventData.data.sections[splits[0]].modules[splits[1]]['data'][
        splits[2]
      ] = imageObj[name];
      return setEventData(_eventData);
    }

    const _eventData = { ...eventData };
    _eventData.data.sections[splits[0]].modules[splits[1]]['data'][splits[2]] =
      await _cropAndUploadFile(name, e.target.files[0]);
    setEventData(_eventData);
  };

  const _cropAndUploadFile = async (name, file) => {
    const urls = [];
    //파일 업로드
    const image = document.createElement('img');
    image.src = URL.createObjectURL(file);
    await new Promise((resolve, reject) => {
      image.onload = async () => {
        const { width, height } = image;
        const files = await cropImage(image, width, height, width);
        const temp = await handleChangeMultiFiles(
          { target: { files, name } },
          {},
        );
        temp[name].forEach((url) => urls.push(url));
        resolve();
      };
    });
    return urls;
  };

  const _removeModuleImage = (sectionIdx, moduleIdx, imgIdx) => {
    const _eventData = { ...eventData };
    _eventData.data.sections[sectionIdx].modules[moduleIdx]['data'][
      'imageUrlList'
    ].splice(imgIdx, 1);
    setEventData(_eventData);
  };

  const _addSection = () => {
    const _eventData = { ...eventData };
    _eventData.data.sections.push({
      id: String(generateId()),
      name: '',
      modules: [],
    });
    setEventData(_eventData);
  };

  const _removeSection = (sectionIdx) => {
    const _eventData = { ...eventData };
    _eventData.data.sections.splice(sectionIdx, 1);
    setEventData(_eventData);
  };

  const _changeSection = (sectionIdx, { name, value }) => {
    const _eventData = { ...eventData };

    const removed = _eventData.data.sections.splice(value, 1);

    name === 'up'
      ? _eventData.data.sections.splice(value - 1, 0, removed[0])
      : _eventData.data.sections.splice(value + 1, 0, removed[0]);

    setEventData(_eventData);
  };

  const _showSelectModuleModal = (sectionIdx) => {
    setActiveSectionIdx(sectionIdx);
    setModuleModalOpen(true);
  };

  const _closeSelectModuleModal = () => {
    setModuleModalOpen(false);
  };

  const _selectModule = (module) => {
    const _eventData = { ...eventData };

    const section = _eventData.data.sections[activeSectionIdx];
    const _module = _.cloneDeep(module);
    _module['id'] = String(generateId());
    section.modules.push(_module);

    setEventData(_eventData);
    _closeSelectModuleModal();
  };

  const _removeModule = (sectionIdx, moduleIdx) => {
    const _eventData = { ...eventData };
    _eventData.data.sections[sectionIdx].modules.splice(moduleIdx, 1);
    setEventData(_eventData);
  };

  const _moveModule = (sectionIdx, moduleIdx, direction) => {
    const _eventData = { ...eventData };

    const removed = _eventData.data.sections[sectionIdx].modules.splice(
      moduleIdx,
      1,
    );

    direction === 'up'
      ? _eventData.data.sections[sectionIdx].modules.splice(
          moduleIdx - 1,
          0,
          removed[0],
        )
      : _eventData.data.sections[sectionIdx].modules.splice(
          moduleIdx + 1,
          0,
          removed[0],
        );

    setEventData(_eventData);
  };

  const _addOrEditEvent = () => {
    const _eventData = { ...eventData };

    if (
      ['store_information', 'challenge_information'].includes(
        eventData.data.type,
      )
    ) {
      _eventData.data.sections.forEach((s) => {
        s.modules = s.modules.filter((m) => m.name !== 'ItemList');
      });
    }

    // 섹션 > module > data 처리
    _eventData.data.sections.forEach((section) => {
      section.modules.forEach((module) => {
        formattingData(module.data);
      });
    });

    // _eventData > data 처리
    formattingData(_eventData.data);

    // _eventData > data > ctaButton
    formattingData(_eventData.data.ctaButton);

    addOrEditEvent(_eventData);
  };

  const formattingData = (data) => {
    Object.keys(data).forEach((key) => {
      if (key === 'curationId') {
        data[key] = Number(data[key]);
      } else if (key === 'payload') {
        Object.keys(data[key]).forEach((k) => {
          if (k === 'raceId') {
            data[key][k] = Number(data[key][k]);
          }
        });
      }

      // bool 값 처리
      if (data[key] === 'true') {
        data[key] = true;
      } else if (data[key] === 'false') {
        data[key] = false;
      }
    });
  };

  return (
    <Container>
      <Form>
        <h3>기본 정보</h3>
        <Form.Group grouped>
          <label>이벤트 종류</label>
          {newEventTypeList.map((eventType) => (
            <Form.Field
              key={eventType.key}
              control={Radio}
              label={eventType.label}
              value={eventType.key}
              name="type"
              checked={eventData.data.type === eventType.key}
              onChange={_handleRadioChange}
            />
          ))}
        </Form.Group>
        <Form.Field>
          <label>
            이벤트 명 (https://web.chlngers.com/events/challenge/이벤트명)
          </label>
          <Input
            name="eventName"
            value={eventData.eventName}
            onChange={_handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>
            노출시작일 (노출 설정된 기간에만 이벤트 페이지가 정상동작함)
          </label>
          <Input
            type="date"
            name="startTime"
            value={eventData.startTime}
            onChange={_handleRadioChange}
          />
        </Form.Field>
        <Form.Field>
          <label>
            노출종료일 (노출 설정된 기간에만 이벤트 페이지가 정상동작함)
          </label>
          <Input
            type="date"
            name="endTime"
            value={eventData.endTime}
            onChange={_handleRadioChange}
          />
        </Form.Field>

        <Divider />

        <h3>og 정보 관리</h3>
        <Form.Field>
          <label>og image (800 * 400 권장)</label>
          <Input
            type="file"
            name="ogImage"
            onChange={_handleChangeSingleFile}
          />
          {!!eventData.data.og.image && (
            <ImageWrapper>
              <Image src={eventData.data.og.image} size="small" />
              <ImageRemoveIcon
                top={'0px'}
                left={'0px'}
                onClick={() => _removeImage('ogImage')}
              />
            </ImageWrapper>
          )}
        </Form.Field>
        <Form.Field>
          <label>og title</label>
          <Input
            name="title"
            value={eventData.data.og.title}
            onChange={_handleOgTextChange}
          />
        </Form.Field>
        <Form.Field>
          <label>og description</label>
          <Input
            name="description"
            value={eventData.data.og.description}
            onChange={_handleOgTextChange}
          />
        </Form.Field>
        <Form.Field>
          <label>공유 메세지</label>
          <Input
            name="shareMessage"
            value={eventData.data.og.shareMessage}
            onChange={_handleOgTextChange}
          />
        </Form.Field>
        <Form.Field>
          <label>최소 지원 버전</label>
          <Input
            name="minVersionSupport"
            value={eventData.data.og.minVersionSupport}
            onChange={_handleOgTextChange}
          />
        </Form.Field>

        <Divider />

        <h3>이벤트 페이지 노출되는 정보</h3>
        {eventData.data.type === 'redirect' ? (
          <Form.Field>
            <label>리다이렉트 링크</label>
            <Input name="redirectUrl" onChange={_handleChange} />
          </Form.Field>
        ) : (
          <>
            <Form.Group grouped>
              <label>내비게이션 사용 여부</label>
              <Form.Field
                control={Radio}
                label="사용"
                value="true"
                name="useNavigation"
                checked={eventData.data.useNavigation === 'true'}
                onChange={_handleRadioChange}
              />
              <Form.Field
                control={Radio}
                label="미사용"
                value="false"
                name="useNavigation"
                checked={eventData.data.useNavigation === 'false'}
                onChange={_handleRadioChange}
              />
            </Form.Group>
            {eventData.data.useNavigation === 'true' ? (
              <>
                <h5>
                  내비게이션을 사용할 경우 내비게이션을 누를 때마다 이동될
                  [섹션] 기준으로 페이지가 구성됩니다.
                </h5>
                <S.LabelButton onClick={_addSection}>
                  내비게이션 섹션 추가
                </S.LabelButton>
                {eventData.data.sections.map((section, sectionIdx) => (
                  <S.SectionWrapper key={section.id}>
                    <S.RowContainer>
                      <Button
                        size="mini"
                        icon={'angle up'}
                        name="up"
                        value={sectionIdx}
                        onClick={_changeSection}
                      />
                      <Button
                        size="mini"
                        icon={'angle down'}
                        name="down"
                        value={sectionIdx}
                        onClick={_changeSection}
                      />
                      <Button
                        size="mini"
                        icon={'delete'}
                        value={sectionIdx}
                        onClick={() => _removeSection(sectionIdx)}
                      />
                    </S.RowContainer>
                    <h4>[섹션 {sectionIdx + 1}]</h4>
                    <Form.Field>
                      <label>섹션명 (내비게이션 메뉴명)</label>
                      <TextArea
                        name={`${sectionIdx}-name`}
                        value={section.name}
                        onChange={_handleSectionChange}
                      />
                    </Form.Field>
                    <S.RowContainer>
                      <h5>모듈 목록</h5>
                      <div>
                        <S.LabelButton
                          margin={'0 0 0 4px'}
                          onClick={() => _showSelectModuleModal(sectionIdx)}
                        >
                          모듈 추가
                        </S.LabelButton>
                      </div>
                    </S.RowContainer>
                    <div>
                      {section.modules.map((module, moduleIdx) => (
                        <S.ModuleWrapper key={module.id}>
                          <S.RowContainer>
                            <Button
                              size="mini"
                              icon={'angle up'}
                              onClick={() =>
                                _moveModule(sectionIdx, moduleIdx, 'up')
                              }
                            />
                            <Button
                              size="mini"
                              icon={'angle down'}
                              onClick={() =>
                                _moveModule(sectionIdx, moduleIdx, 'down')
                              }
                            />
                            <Button
                              size="mini"
                              icon={'delete'}
                              value={sectionIdx}
                              onClick={() =>
                                _removeModule(sectionIdx, moduleIdx)
                              }
                            />
                          </S.RowContainer>
                          <EventModule
                            eventType={eventData.data.type}
                            sectionIdx={sectionIdx}
                            moduleIdx={moduleIdx}
                            module={module}
                            challengeOptions={challengeOptions}
                            goalOptions={goalOptions}
                            raceOptions={raceOptions}
                            eventOptions={eventOptions}
                            banners={banners}
                            colorOptions={colorOptions}
                            handleChangeFile={_handleChangeModuleFile}
                            removeImage={_removeModuleImage}
                            handleChange={_handleModuleChange}
                            handleRadioChange={_handleModuleRadioChange}
                          />
                        </S.ModuleWrapper>
                      ))}
                    </div>
                  </S.SectionWrapper>
                ))}
              </>
            ) : (
              <>
                <h5>
                  내비게이션 미사용일 경우 섹션 구분 없이 모듈만 추가하시면
                  됩니다.
                </h5>
                <S.SectionWrapper>
                  <S.RowContainer>
                    <h5>모듈 목록</h5>
                    <div>
                      <S.LabelButton
                        margin={'0 0 0 4px'}
                        onClick={() => _showSelectModuleModal(0)}
                      >
                        모듈 추가
                      </S.LabelButton>
                    </div>
                  </S.RowContainer>
                  <div>
                    {eventData.data.sections[0].modules.map(
                      (module, moduleIdx) => (
                        <S.ModuleWrapper key={module.id}>
                          <S.RowContainer>
                            <Button
                              size="mini"
                              icon={'angle up'}
                              onClick={() => _moveModule(0, moduleIdx, 'up')}
                            />
                            <Button
                              size="mini"
                              icon={'angle down'}
                              onClick={() => _moveModule(0, moduleIdx, 'down')}
                            />
                            <Button
                              size="mini"
                              icon={'delete'}
                              value={0}
                              onClick={() => _removeModule(0, moduleIdx)}
                            />
                          </S.RowContainer>
                          <EventModule
                            eventType={eventData.data.type}
                            sectionIdx={0}
                            moduleIdx={moduleIdx}
                            module={module}
                            challengeOptions={challengeOptions}
                            goalOptions={goalOptions}
                            raceOptions={raceOptions}
                            eventOptions={eventOptions}
                            banners={banners}
                            colorOptions={colorOptions}
                            handleChangeFile={_handleChangeModuleFile}
                            removeImage={_removeModuleImage}
                            handleChange={_handleModuleChange}
                            handleRadioChange={_handleModuleRadioChange}
                          />
                        </S.ModuleWrapper>
                      ),
                    )}
                  </div>
                </S.SectionWrapper>
              </>
            )}

            <Form.Group grouped>
              <label>플로팅(CTA) 버튼 사용 여부</label>
              <Form.Field
                control={Radio}
                label="사용"
                value="true"
                name="useCTAButton"
                checked={eventData.data.useCTAButton === 'true'}
                onChange={_handleRadioChange}
              />
              <Form.Field
                control={Radio}
                label="미사용"
                value="false"
                name="useCTAButton"
                checked={eventData.data.useCTAButton === 'false'}
                onChange={_handleRadioChange}
              />
            </Form.Group>

            {eventData.data.useCTAButton === 'true' && (
              <CTAButton
                ctaButton={eventData.data.ctaButton}
                challengeOptions={challengeOptions}
                raceOptions={raceOptions}
                colorOptions={colorOptions}
                eventOptions={eventOptions}
                handleChange={_handleCTAButtonTextChange}
                handleRadioChange={_handleCTAButtonRadioChange}
              />
            )}
          </>
        )}
      </Form>

      <S.ButtonWrapper>
        <Button fluid color="blue" content="확인" onClick={_addOrEditEvent} />
      </S.ButtonWrapper>

      <Modal
        size="tiny"
        open={moduleModalOpen}
        onClose={_closeSelectModuleModal}
      >
        <Modal.Header>모듈 선택</Modal.Header>
        <Modal.Content>
          <S.RowWrapContainer>
            {modules.map((module) => {
              if (
                eventData.data.type.includes('information') &&
                [moduleType.itemList, moduleType.countDown].includes(
                  module.name,
                )
              ) {
                return null;
              }
              return (
                <S.LabelButton
                  key={module.name}
                  margin={'8px'}
                  size={'large'}
                  onClick={() => _selectModule(module)}
                >
                  {module.text}
                </S.LabelButton>
              );
            })}
          </S.RowWrapContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={_closeSelectModuleModal}>
            닫기
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default EventForm;
