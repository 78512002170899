import { Dropdown } from 'semantic-ui-react';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import { serviceCategoryOptions } from '@constant/common';
import { ServiceCategory } from '@types';

interface Props {
  value?: ServiceCategory;
  onChange?: (value: ServiceCategory) => void;
}

const ServiceCategorySelector = ({ value, onChange }: Props) => {
  return (
    <FlexBox.Column>
      <Label>카테고리 (대분류)</Label>
      <Dropdown
        placeholder="카테고리를 선택하세요"
        fluid
        search
        selection
        options={serviceCategoryOptions}
        value={value}
        onChange={(__, item) => onChange?.(item.value as ServiceCategory)}
      />
    </FlexBox.Column>
  );
};

export default ServiceCategorySelector;
