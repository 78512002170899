import { Dropdown } from 'semantic-ui-react';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import { serviceChannelOptions } from '@constant/common';
import { PurchaseChannel } from '@types';

interface Props {
  value?: PurchaseChannel;
  onChange?: (value: PurchaseChannel) => void;
}

const PurchaseChannelSelector = ({ value, onChange }: Props) => {
  return (
    <FlexBox.Column>
      <Label>구매장소</Label>
      <Dropdown
        placeholder="구매장소를 선택하세요"
        fluid
        search
        selection
        options={serviceChannelOptions}
        value={value}
        onChange={(__, item) => onChange?.(item.value as PurchaseChannel)}
      />
    </FlexBox.Column>
  );
};

export default PurchaseChannelSelector;
