import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Loader } from 'semantic-ui-react';
import { apis } from '../../../api';
import RaceMenu from '../RaceMenu/RaceMenu';
import { RaceCardInfo } from './components/RaceCardInfo';
import RaceHomeDescription from './components/RaceHomeDescription/RaceHomeDescription';
import RaceImageInfo from './components/RaceImageInfo';
import RaceInfo from './components/RaceInfo';
import RaceLeageInfo from './components/RaceLeageInfo';
import RaceOptionTable from './components/RaceOptionTable';
import { RacePackageInfo } from './components/RacePackageInfo';
import RaceProgressInfoDescription from './components/RaceProgressInfoDescription';
import RacePromiseInfo from './components/RacePromiseInfo/RacePromiseInfo';
import RaceRegisterInfoDescription from './components/RaceRegisterInfoDescription';
import RaceReviewQuestion from './components/RaceReviewQuestion';
import RaceReviewTable from './components/RaceReviewTable/RaceReviewTable';
import RaceShareInfo from './components/RaceShareInfo';
import RaceSponsorInfo from './components/RaceSponsorInfo';
import RaceTransactionTable from './components/RaceTransactionTable';
import RaceImage from '../RaceForm/components/RaceImage';
import OfferWallInfo from './components/OfferWallInfo/OfferWallInfo';
import {
  RaceAllInfoForAdmin,
  RaceImageFilterWithLeagueId,
  RaceReviewForAdmin,
  TransactionRaceForAdmin,
} from 'src/types/race.types';
import { LabelMiniInfo, OfferWallInfoForRaceAdmin, Race } from '@types';
import LabelInfo from './components/LabelInfo/LabelInfo';

const RaceDetail = () => {
  const { id: raceId } = useParams() as { id: string };

  const [race, setRace] = useState<RaceAllInfoForAdmin | undefined>();
  const [offerWallInfo, setOfferWallInfo] = useState<
    OfferWallInfoForRaceAdmin[]
  >([]);
  const [productPackages, setProductPackages] = useState<
    Race.ReqCreateOrUpdateProductPackage[]
  >([]);
  const [labels, setLabels] = useState<LabelMiniInfo[]>([]);
  const [transactions, setTransactions] = useState<TransactionRaceForAdmin[]>(
    [],
  );
  const [reviews, setReviews] = useState<RaceReviewForAdmin[]>([]);
  const [filters, setFilters] = useState<RaceImageFilterWithLeagueId[]>([]);

  useEffect(() => {
    _getRaceDetail();
    _getRaceTransactions();
    _getRaceReviews();
  }, []);

  const _getRaceDetail = async () => {
    const {
      race: _race,
      packages: _productPackages,
      filters: _filters,
      offerWallInfo: _offerWallInfo,
      labels: _labels,
    } = await apis.getRaceDetail(Number(raceId));
    setProductPackages(_productPackages);
    setFilters(_filters);
    setRace(_race);
    setOfferWallInfo(_offerWallInfo);
    setLabels(_labels);
  };

  const _getRaceTransactions = async () => {
    const { transactions: _transactions } = await apis.getRaceTransactions(
      Number(raceId),
    );
    setTransactions(_transactions);
  };

  const _getRaceReviews = async () => {
    const { reviews: _reviews } = await apis.getRaceReviews(Number(raceId));
    setReviews(_reviews);
  };

  if (!race) return <Loader />;
  return (
    <Grid columns={'equal'} style={{ margin: 50 }}>
      <Grid.Column width={2}>
        <RaceMenu />
      </Grid.Column>
      <Grid.Column>
        <RaceInfo race={race} />
        <OfferWallInfo offerWallInfo={offerWallInfo} />
        <LabelInfo labels={labels} />

        <RaceOptionTable race={race} productPackages={productPackages} />

        <RaceImageInfo race={race} />
        <RaceCardInfo info={race.info.cardInfo} />
        <RaceShareInfo info={race.info} />
        <RacePromiseInfo promise={race.info.promise} />

        <Grid columns={3}>
          <Grid.Column>
            <RaceProgressInfoDescription
              progressInfoDescriptionModule={
                race.modules.progressInfoDescriptionModule
              }
            />
          </Grid.Column>
          <Grid.Column>
            <RaceRegisterInfoDescription
              registerInfoDescriptionModule={
                race.modules.registerInfoDescriptionModule
              }
            />
          </Grid.Column>
          <Grid.Column>
            <RaceHomeDescription homeModule={race.modules.homeModule} />
          </Grid.Column>
        </Grid>

        <RacePackageInfo packages={race.productDescriptions || []} />

        <Grid columns={2}>
          <Grid.Column>
            <RaceSponsorInfo sponsors={race.sponsors} />
          </Grid.Column>
          <Grid.Column>
            <RaceLeageInfo leagues={race.leagues} filters={filters} />
          </Grid.Column>
        </Grid>

        <RaceReviewQuestion reviewInfo={race.reviewInfo} />

        <Divider hidden />

        <Divider hidden />

        <Divider hidden />

        <RaceTransactionTable
          race={race}
          transactions={transactions}
          productPackages={productPackages}
          getRaceTransactions={_getRaceTransactions}
        />

        <Divider hidden />

        <RaceReviewTable race={race} reviews={reviews} />

        <Divider hidden />

        <RaceImage raceId={race.id} />

        <Divider hidden />
      </Grid.Column>
    </Grid>
  );
};

export default RaceDetail;
