import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import FlexBox from '@component/FlexBox/FlexBox';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import ErrorMsg from '@component/ErrorMsg';
import Label from '@component/Label';
import { useEffect, useState } from 'react';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './IsDeletedForm.serializer';
import { useIsDeleted, useIsDeletedActions } from './IsDeletedForm.store';
import { validate } from './IsDeletedForm.validator';
import { getChallengeId } from '../../NewChallengeForm.util';

const options = {
  true: { label: '삭제', value: true },
  false: { label: '공개', value: false },
};
const IsDeletedForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'isDeleted'>,
  Pick<ChallengeFormType, 'isDeleted'>,
  Pick<ReqCreateOrUpdateChallenge, 'isDeleted'>
> = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const { isDeleted = false } = useIsDeleted();
  const { setIsDeleted } = useIsDeletedActions();

  useEffect(() => {
    const challengeId = getChallengeId();
    setDisabled(Boolean(!challengeId));
  }, []);

  if (disabled) return <></>;

  const validated = validate({ isDeleted });

  return (
    <FlexBox.Column>
      <Label>챌린지 삭제 처리</Label>
      <RadioSelector
        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
        options={Object.values(options)}
        onChange={({ value }) => setIsDeleted(value)}
        selectedOption={options[isDeleted.toString() as 'true' | 'false']}
      />
      <ErrorMsg text={!validated.isValid ? validated.message : ''} />
    </FlexBox.Column>
  );
};

export default IsDeletedForm;

IsDeletedForm.validate = validate;
IsDeletedForm.convertData2Form = convertData2Form;
IsDeletedForm.convertForm2Data = convertForm2Data;
